import Stepper from "../../../Chavelusa/Stepper";
import FormGroupLoader from "../../../Chavelusa/FormGroupLoader";

import {
    buildFilterSelect,
    buildFilterText,
    buildFilterRichText,
    buildFilterCheckboxOnlyOne,
    buildFilterCheckboxMultiple,
    buildFilterSwitch
} from "../../../Chavelusa/FormGroup/utils";

import { canWriteGenericData, canWriteSensitiveData, is_agent, ROLE_AGENT } from "../../../../../Services/permissions";
import { PROPERTY_STATUS_ALLOW_WRITE_ALWAYS } from "../../../../../Models/property";

import ReactPlayer from "react-player";

const message_NotEnoughPermissions = "Não tem permissões para editar este campo";
const message_CantChangeAfterDefined = "Este campo não pode ser alterado depois de definido";


export const propertyForm = (property, goBack, updateEntity, onChange) => {

    const read_only_lax = canWriteSensitiveData || !!property && PROPERTY_STATUS_ALLOW_WRITE_ALWAYS.includes(property.status);

    console.log("propertyForm", !!property && property.id);

    const urlPost = '/realstate/properties/' + (!!property ? property.id + '/' : '');

    // Define steps
    let steps = [];
    if (!property) {
        let propertyInitialFields = is_agent ? [] : [{
            ...buildFilterSelect('Agente', 'select', 'agent_id', [], null, true),
            api: {
                url: '/realstate/agents/',
                labelTag: 'name',
                idTag: 'id'
            }
        }];
        propertyInitialFields.push({
            ...buildFilterSelect('Proprietário', 'select', 'client_id', [], null, true),
            api: {
                url: '/crm/client_agents?exclude_migrated=true&search=<searchTerm>' + (is_agent ? '' : '&agent=<dependantValue>'),
                labelTag: 'client.name',
                idTag: 'client.id',
                dependsOn: is_agent ? undefined : 'agent_id',
                async: true,
                bodyAttr: 'results'
            },
            autoComplete: true
        });
        propertyInitialFields.push(buildFilterText(
            'Código-postal', 'zipCode', true,
            (val) => {
                return val && /^[1-9]{1}[0-9]{3}\-[0-9]{3}$/.test(val)
            }
        ));
        propertyInitialFields.push(buildFilterText(
            'Título', 'title', true
        ));
        steps = [
            {
                label: 'Criar propriedade',
                component: <FormGroupLoader
                    fields={propertyInitialFields}
                    title={'Criar propriedade'}
                    urlPost={urlPost}
                    edit={false}
                    onSubmitSuccess={updateEntity}
                    initialVals={undefined}
                    updateParams={onChange}
                />,
                initialized: false
            }
        ];
    } else {
        steps = [
            {
                label: 'Localização',
                component: <FormGroupLoader
                    fields={[
                        {
                            ...buildFilterText(
                                'Morada', 'address', true, undefined, undefined, 'text', true, 3, null, !(!property.address || read_only_lax)
                            ),
                            info: !(!property.address || read_only_lax) ? message_NotEnoughPermissions : undefined
                        }
                        ,
                        property.zipCodeFirst && property.zipCodeFinal
                            ? { ...buildFilterText('Código-postal', 'zipCode', true), disabled: true, info: message_CantChangeAfterDefined }
                            : buildFilterText(
                                'Código-postal', 'zipCode', true,
                                (val) => {
                                    return val && /^[1-9]{1}[0-9]{3}\-[0-9]{3}$/.test(val)
                                }
                            ),
                        property.parish
                            ? { ...buildFilterText('Distrito', 'parish.county.district.name', true), disabled: true, info: message_CantChangeAfterDefined }
                            : {
                                ...buildFilterSelect('Distrito', 'select', 'district', [], null, true),
                                api: {
                                    url: '/realstate/districts/',
                                    labelTag: 'name',
                                    idTag: 'id'
                                }
                            },
                        property.parish
                            ? { ...buildFilterText('Concelho', 'parish.county.name', true), disabled: true, info: message_CantChangeAfterDefined }
                            : {
                                ...buildFilterSelect('Concelho', 'select', 'county', [], null, true),
                                api: {
                                    url: '/realstate/counties/?district=<dependantValue>',
                                    labelTag: 'name',
                                    idTag: 'id',
                                    dependsOn: 'district'
                                }
                            },
                        property.parish
                            ? { ...buildFilterText('Freguesia', 'parish.name', true), disabled: true, info: message_CantChangeAfterDefined }
                            : {
                                ...buildFilterSelect('Freguesia', 'select', 'parish_id', [], null, true),
                                api: {
                                    url: '/realstate/parishes/?county=' + (!property.parish ? '<dependantValue>' : property.parish.county.id),
                                    labelTag: 'name',
                                    idTag: 'id',
                                    dependsOn: !property.parish ? 'county' : undefined
                                }
                            },
                        {
                            ...buildFilterText(
                                'Coordenadas', 'coordinates', false,
                                (val) => {
                                    // Expected format example: 39.458495, -8.430464
                                    if (val.indexOf(',') < 0) return false;
                                    if (val.split(',').length !== 2) return false;
                                    if (isNaN(val.split(',')[0])) return false;
                                    if (isNaN(val.split(',')[1])) return false;
                                    return true;
                                }
                            ),
                            info: 'Quando preenchido o mapa é visível na página pública da propriedade. Formato: LATITUDE, LONGITUDE',
                            disabled: !(!property.coordinates || read_only_lax), 
                            info: !(!property.coordinates || read_only_lax) ? message_NotEnoughPermissions : undefined
                        },
                    ]}
                    title={'Localização'}
                    urlPost={urlPost}
                    edit={!!property}
                    onSubmitSuccess={updateEntity}
                    initialVals={property ? {
                        ...property,
                        zipCode: property.zipCodeFirst && property.zipCodeFinal ? property.zipCodeFirst + '-' + property.zipCodeFinal : ''
                    } : undefined}
                    updateParams={onChange}
                />,
                initialized: !!property.parish
            }
        ];

        steps.push(
            {
                label: 'Informações gerais',
                component: <FormGroupLoader
                    fields={[
                        !(!property.type || read_only_lax)
                            ? { ...buildFilterText('Tipo', 'type.name', true), disabled: true, info: message_NotEnoughPermissions }
                            : {
                                ...buildFilterSelect('Tipo', 'select', 'type_id', [], null, true),
                                api: {
                                    url: '/realstate/property_types/',
                                    labelTag: 'name',
                                    idTag: 'id'
                                },
                            },
                        {
                            ...buildFilterSelect('Estado de conservação', 'select', 'conservation', [], null, true),
                            api: {
                                url: '/realstate/conservations',
                                labelTag: 'label',
                                idTag: 'id'
                            }
                        },
                        buildFilterText(
                            'Ano de construção', 'constructionYear', false,
                            (val) => {
                                let year = new Date().getFullYear();
                                return val > (year - 150) && val <= year;
                            }
                            , undefined, 'number'
                        ),
                        {
                            ...buildFilterSelect('Classe energética', 'select', 'energyclass', [], null, true),
                            api: {
                                url: '/realstate/energy_classes',
                                labelTag: 'label',
                                idTag: 'id'
                            }
                        },
                        buildFilterText(
                            'Número do certificado energético', 'energyCertificateNumber', false
                        ),
                    ]}
                    title={'Informações gerais'}
                    urlPost={urlPost}
                    edit={!!property}
                    onSubmitSuccess={updateEntity}
                    initialVals={property ? property : undefined}
                    updateParams={onChange}
                />,
                initialized: !!property.type
            }
        );
        steps.push(
            {
                label: 'Anúncio',
                component: <FormGroupLoader
                    fields={[
                        {
                            ...buildFilterSelect('Finalidade', 'select', 'state', [], null, true),
                            api: {
                                url: '/realstate/states',
                                labelTag: 'label',
                                idTag: 'id'
                            }
                        },
                        buildFilterText(
                            'Preço', 'price', true,
                            (val) => {
                                return val > 0;
                            }
                            , undefined, 'number'
                        ),
                        buildFilterSwitch(
                            'Propriedade oculta', 
                            'exclusive', 
                            false,
                            null,
                            !read_only_lax,
                            !read_only_lax ? message_NotEnoughPermissions : undefined
                        ),
                        buildFilterSwitch(
                            'Preço sob consulta', 
                            'priceHidden', 
                            false,
                            null,
                            !read_only_lax,
                            !read_only_lax ? message_NotEnoughPermissions : undefined
                        ),
                        buildFilterSwitch(
                            'Preço negociável', 
                            'negociable', 
                            false
                        ),
                        buildFilterSwitch(
                            'Aceita permuta', 
                            'exchange', 
                            false
                        ),
                        buildFilterSwitch(
                            'Imóvel da banca', 
                            'bankproperty', 
                            false
                        ),
                        buildFilterText(
                            'Título', 'title', true
                        ),
                        buildFilterRichText(
                            'Descrição', 'description', true
                        )

                    ]}
                    title={'Anúncio'}
                    urlPost={urlPost}
                    edit={!!property}
                    onSubmitSuccess={updateEntity}
                    initialVals={property ? property : undefined}
                    updateParams={onChange}
                />,
                initialized: !!property.state
            }
        );

        if (property && property.house)
            steps.push({
                label: 'Moradias e apartamentos',
                component: <FormGroupLoader
                    fields={[
                        {
                            ...buildFilterText(
                                'Licença de utilização',
                                'house.usageLicense',
                                false
                            ), info: 'Obrigatório para moradias e apartamento para arrendar'
                        },
                        buildFilterCheckboxOnlyOne(
                            'Rendas acessíveis',
                            'house.affordableRents'
                        ),
                        buildFilterCheckboxOnlyOne(
                            'Aceita estudantes',
                            'house.studentAccommodation'
                        )
                    ]}
                    title={'Moradias e apartamentos'}
                    urlPost={urlPost}
                    edit={!!property}
                    onSubmitSuccess={updateEntity}
                    initialVals={property ? property : undefined}
                    updateParams={onChange}
                />,
                initialized: true
            })
        if (property && property.lot)
            steps.push({
                label: 'Terrenos',
                component: <FormGroupLoader
                    fields={[
                        {
                            ...buildFilterSelect('Propósito', 'select', 'lot.purpose', [], null, true),
                            api: {
                                url: '/realstate/lots/purposes',
                                labelTag: 'label',
                                idTag: 'id'
                            }
                        },
                        {
                            ...buildFilterSelect('Tipo de terreno', 'select', 'lot.landType', [], null, true),
                            api: {
                                url: '/realstate/lots/lands',
                                labelTag: 'label',
                                idTag: 'id'
                            }
                        },
                        {
                            ...buildFilterSelect('Licença de construção', 'select', 'lot.license', [], null, true),
                            api: {
                                url: '/realstate/lots/licenses',
                                labelTag: 'label',
                                idTag: 'id'
                            }
                        },
                        {
                            ...buildFilterSelect('Inclinação', 'select', 'lot.slope', [], null, true),
                            api: {
                                url: '/realstate/lots/slopes',
                                labelTag: 'label',
                                idTag: 'id'
                            }
                        },
                    ]}
                    title={'Terrenos'}
                    urlPost={urlPost}
                    edit={!!property}
                    onSubmitSuccess={updateEntity}
                    initialVals={property ? property : undefined}
                    updateParams={onChange}
                />,
                initialized: !!property.lot && !!property.lot.purpose
            })
        if (property && property.store)
            steps.push({
                label: 'Lojas',
                component: <FormGroupLoader
                    fields={[
                        buildFilterText(
                            'Número de divisões', 'store.divisions', true,
                            (val) => {
                                return val > 0;
                            }
                            , undefined, 'number'
                        ),
                        buildFilterText(
                            'Número de andares', 'store.floors', true,
                            (val) => {
                                return val > 0;
                            }
                            , undefined, 'number'
                        ),
                        {
                            ...buildFilterSelect('Tipo', 'select', 'store.type', [], null, true),
                            api: {
                                url: '/realstate/stores/types',
                                labelTag: 'label',
                                idTag: 'id'
                            }
                        },
                    ]}
                    title={'Lojas'}
                    urlPost={urlPost}
                    edit={!!property}
                    onSubmitSuccess={updateEntity}
                    initialVals={property ? property : undefined}
                    updateParams={onChange}
                />,
                initialized: !!property.store && !!property.store.floors
            })
        if (property && property.building)
            steps.push({
                label: 'Edifícios',
                component: <FormGroupLoader
                    fields={[
                        {
                            ...buildFilterSelect('Tipo', 'select', 'building.feature', [], null, true),
                            api: {
                                url: '/realstate/buildings/features',
                                labelTag: 'label',
                                idTag: 'id'
                            }
                        },
                        buildFilterText(
                            'Número de andares', 'building.floors', true,
                            (val) => {
                                return val > 0;
                            }
                            , undefined, 'number'
                        ),
                        buildFilterText(
                            'Número de elevadores', 'building.lifts', true,
                            (val) => {
                                return val >= 0;
                            }
                            , undefined, 'number'
                        ),
                        buildFilterText(
                            'Número de lugares de estacionamento', 'building.parkingSlots', true,
                            (val) => {
                                return val >= 0;
                            }
                            , undefined, 'number'
                        ),
                    ]}
                    title={'Edifícios'}
                    urlPost={urlPost}
                    edit={!!property}
                    onSubmitSuccess={updateEntity}
                    initialVals={property ? property : undefined}
                    updateParams={onChange}
                />,
                initialized: !!property.building && !!property.building.feature
            })

        steps.push({
            label: 'Números',
            component: <FormGroupLoader
                fields={[
                    {...buildFilterText(
                        'Quartos', 'bedrooms', true,
                        (val) => {
                            return val >= 0;
                        }
                        , undefined, 'number'
                    ), acceptsZero: true},
                    {...buildFilterText(
                        'Casas de banho', 'bathrooms', true,
                        (val) => {
                            return val >= 0;
                        }
                        , undefined, 'number'
                    ), acceptsZero: true},
                    {
                        ...buildFilterText(
                            'Área total', 'area', true,
                            (val) => {
                                return val > 0;
                            }
                            , undefined, 'number'
                        ),
                        info: 'Bruta, ou do lote'
                    },
                    {...buildFilterText(
                        'Área do terreno', 'areaground', true,
                        (val) => {
                            return val >= 0;
                        }
                        , undefined, 'number'
                    ), acceptsZero: true},
                    {
                        ...buildFilterText(
                            'Área útil', 'areaconstructed', true,
                            (val) => {
                                return val > 0;
                            }
                            , undefined, 'number'
                        ),
                        info: 'Interior do imóvel'
                    },
                ]}
                title={'Números'}
                urlPost={urlPost}
                edit={!!property}
                onSubmitSuccess={updateEntity}
                initialVals={property ? property : undefined}
                updateParams={onChange}
            />,
            initialized: !!property.area
        })
        steps.push({
            label: 'Características',
            component: <FormGroupLoader
                fields={[
                    {
                        ...buildFilterCheckboxMultiple('.', 'feature.id'),
                        api: {
                            multiple: true,
                            url: '/realstate/featuresGroups?propertyType=' + (property ? property.type_id : ''),
                            groupTag: 'name',
                            groupArray: 'features',
                            labelTag: 'name',
                            idTag: 'id',
                            patchArray: 'feature',
                            patchObject: 'id'
                        }
                    },
                ]}
                title={'Características'}
                urlPost={urlPost}
                edit={!!property}
                onSubmitSuccess={updateEntity}
                initialVals={property ? property : undefined}
                updateParams={onChange}
            />,
            initialized: !!property.feature && property.feature.length > 0

        })
        steps.push({
            label: 'Vídeo',
            component: <FormGroupLoader
                fields={[
                    {
                        ...buildFilterText(
                            'Hiperligação', 'video.url', false,
                            (val) => {
                                return !!val
                                    && (val.indexOf("youtube.com") >= 0 || val.indexOf("vimeo.com") >= 0)
                                    && ReactPlayer.canPlay(val)
                                    ;
                            }
                        ),
                        info: 'São aceites vídeos do Youtube ou Vimeo (links curtos não são válidos)'
                    }
                ]}
                title={'Vídeo'}
                urlPost={urlPost}
                edit={!!property}
                onSubmitSuccess={updateEntity}
                initialVals={property ? property : undefined}
                updateParams={onChange}
            />,
            initialized: !!property.video
        })
    }

    // Evaluate property status and set steps active
    let activeStepsInitial = [];
    let completedSteps = [];
    let anyIncomplete = false;
    if (!property) {
        activeStepsInitial = [0];
    } else {
        steps.forEach((s, i) => {
            if (!anyIncomplete)
                activeStepsInitial.push(i);
            if (anyIncomplete)
                return;
            if (s.initialized)
                completedSteps.push(i);
            else
                anyIncomplete = true;
        })
    }
    console.log("completedSteps", completedSteps, steps);


    return (<Stepper
        title={property ? "A editar propriedade" : "A criar propriedade"}
        steps={steps}
        activeStepsInitial={activeStepsInitial}
        completed={completedSteps}
        goBack={!property ? undefined : goBack}
    />);
}